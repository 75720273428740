import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger.js";

gsap.registerPlugin(ScrollTrigger);

gsap.config({
  nullTargetWarn: false
})

export const initGsap = (pageName) => {


  const rsContainerSections = gsap.utils.toArray('header, section, footer');
  const rsAnimDelay = 33;

  rsContainerSections.forEach((section) => {
    const rsRevealAnimations = gsap.utils.toArray('.RS-animation', section);
    
    rsRevealAnimations.forEach((item, index) => {
      gsap.from(item, {
        scrollTrigger: {
          start: 'top bottom',
          end: 'bottom top',
          trigger: item,
          toggleClass: 'RS-animation--enter',
          once: true,
          scrub: 1,
          stagger: 5,
        }
      });
  
      item.style.transitionDelay = `${index * rsAnimDelay}ms`;
    });
  });



  // const rsRevealKv = gsap.utils.toArray('RS-animation__kv-inner', section);
    
  // rsRevealKv.forEach((item) => {
  //   gsap.from(item, {
  //     scrollTrigger: {
  //       start: 'top bottom',
  //       end: 'bottom top',
  //       trigger: item,
  //       toggleClass: 'RS-animation--enter',
  //       once: true,
  //       scrub: 1,
  //       stagger: 5,
  //     }
  //   });
  // });

  // const rsLoopedAnimations = gsap.utils.toArray('.RS-looped-animation');

  // rsLoopedAnimations.forEach(item => {
  //   gsap.from(item, {
  //     scrollTrigger: {
  //       start: 'top bottom',
  //       end: 'bottom top',
  //       trigger: item,
  //       toggleClass: 'RS-looped-animation--running',
  //       scrub: 1
  //     }
  //   });
  // });
  

  const topNavBar = document.querySelector('.RS-top-bar');
  const topNavBarMobileIcon = document.querySelector('.RS-top-bar__mobile-nav-icon');

  ScrollTrigger.create({
    start: 0,
    end: 99999,
    onUpdate: ({direction}) => {
      if (direction == -1) {
        topNavBar.classList.remove('RS-top-bar--hidden');
      } else {
        if(!topNavBarMobileIcon.classList.contains('RS-top-bar__mobile-nav-icon--active')){
          topNavBar.classList.add('RS-top-bar--hidden');
        }
      }
    }
  });


  

  if(pageName === 'Home'){


    const heroMarquee = document.querySelector('.RS-section-marquee--hero');

    ScrollTrigger.create({
      start: 0,
      end: 100,
      onUpdate: ({direction}) => {
        if (direction == -1) {
          heroMarquee.classList.remove('RS-section-marquee--hero--hidden');
        } else {
          heroMarquee.classList.add('RS-section-marquee--hero--hidden');
        }
      }
    });


    ScrollTrigger.create({
      trigger: '#RS-section-intro',
      start: 'top top',
      end: 'bottom center',
      animation: gsap.fromTo('.RS-intro__kv-inner', {translateY: 0 }, {translateY: 64}),
      scrub: 1
    })
  
  }


};